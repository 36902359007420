.termdiv{
  background-color: #ffffff;
  width: 50%;
  border-radius: 20px;
  padding: 2rem;
  display: flex;
  flex-direction: column;

}
.main_title{
margin: 0px 0px 10px 0px;
  text-align: center;
}
/* .terminnerDiv{
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
} */
.terminnerDiv ol{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.pointsp{
  text-align: justify;
}

@media only screen and (max-width: 600px) {
  .termdiv {
    width: 70%;
    padding: 1rem;
  }
}
