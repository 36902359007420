.modal {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: #fff;
    z-index: 1;
    width: 70%;
    text-align: center;
    width: 50%;
    border-radius: 15px;
  }
  .closebtn{
    background: #0fae76;
    color: #fff;
    border: 0;
    border-radius: 50px;
    cursor: pointer;
    font-family: inherit;
    padding: 8px 30px;
    margin: 5px;
    font-size: 18px;
  }